import firebase from 'firebase/app';
import 'firebase/firestore';

export const config = {
    apiKey: "AIzaSyA_Jt6g-HNCF_wpZxpJkKf6gUTY10YZaew",
    authDomain: "food-journal-fde53.firebaseapp.com",
    databaseURL: "https://food-journal-fde53.firebaseio.com",
    projectId: "food-journal-fde53",
    storageBucket: "food-journal-fde53.appspot.com",
    messagingSenderId: "59354937462",
    appId: "1:59354937462:web:97986bf7f8095501890757",
    measurementId: "G-K52B4YBLKY"
}
firebase.initializeApp(config);

const db = firebase.firestore()
db.enablePersistence()
.catch((err) => {
    if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
        console.error("Multiple tabs open, persistence can only be enabled in one tab at a a time.")
    } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
        console.error("The current browser does not support all of the features required to enable persistence.")
    }
});
// Subsequent queries will use persistence, if it was enabled successfully

export{db}