import React from 'react';
import { Box, ResponsiveContext } from 'grommet';

import LabelledMeter from './LabelledMeter';

const NutritionLeft = (props) => {
    return (
        <ResponsiveContext.Consumer>
            {size => (size === 'small'
                ? <Box gap="small">
                    <Box gap='small' direction='row'>
                        <LabelledMeter value={props.total.protein} max={props.target.protein} unit="g" text="Protein" />
                        <LabelledMeter value={props.total.fat} max={props.target.fat} unit="g" text="Fat" />
                    </Box>
                    <Box gap='small' direction='row'>
                        <LabelledMeter value={props.total.carbs} max={props.target.carbs} unit="g" text="Carbs" />
                        <LabelledMeter value={props.total.cal} max={props.target.cal} unit="" text="kcal" />
                    </Box>
                </Box>
                : <Box direction='row' gap="small">
                    <LabelledMeter value={props.total.protein} max={props.target.protein} unit="g" text="Protein" />
                    <LabelledMeter value={props.total.fat} max={props.target.fat} unit="g" text="Fat" />
                    <LabelledMeter value={props.total.carbs} max={props.target.carbs} unit="g" text="Carbs" />
                    <LabelledMeter value={props.total.cal} max={props.target.cal} unit="" text="kcal" />
                </Box>
            )}
        </ResponsiveContext.Consumer>
    );
}

export default NutritionLeft;