import React, { Component } from 'react';
import {
  Form,
  FormField,
  Button,
  Box,
} from 'grommet';
import { inject, observer } from 'mobx-react';

import { DbService } from '../services/DbService';

class AddMeal extends Component {
  goBack = () => {
    if (this.props.goBackAction) {
      this.props.goBackAction();
    } else {
      this.props.store.showSidebarAction(false);
    }
  }
  
  submitForm = (event) => {
    const food = event.value;
    const toInsert = {
      protein: parseFloat(food.protein) || 0,
      fat: parseFloat(food.fat) || 0,
      carbs: parseFloat(food.carbs) || 0,
      serving: parseFloat(food.serving) || 0,
    };

    const foodToUpdate = this.props.store.foodsAll.find(item => item.name === food.name);
    if (foodToUpdate) {
      // doing an update
      DbService.deleteFood(foodToUpdate).then(() => {
        DbService.addFood(food.name, toInsert).then(() => {
          this.props.store.showToastAction(food.name + " was updated");
          this.props.store.getFoodsAll();
          this.goBack();
        });
      });
    } else {
      // doing an insert
      DbService.addFood(food.name, toInsert).then(() => {
        this.props.store.showToastAction(food.name + " was added");
        this.props.store.getFoodsAll();
        this.goBack();
      });
    }
  }

  deleteFoodWithConfirmation = (food) => {
    this.props.store.showConfirmationAction(() => this.deleteFood(food));
  }

  deleteFood = (food) => {
    DbService.deleteFood(food).then(() => {
      this.props.store.showToastAction(food + " was deleted");
      this.props.store.getFoodsAll();
      this.goBack();
    });
  }

  render() {
    const {
      selectedFood,
      foodFormValue,
      setFoodFormValue,
      showBarcodeScannerAction,
    } = this.props.store;    
    return (
      <Box>
        <Form onSubmit={(event) => this.submitForm(event)} value={foodFormValue} onChange={food => setFoodFormValue(food)}>
          <FormField name="name" label="Name" />
          <FormField name="protein" label="Protein (g) in 100g" type="number" step="0.1" />
          <FormField name="fat" label="Fat (g) in 100g" type="number" step="0.1" />
          <FormField name="carbs" label="Carbs (g) in 100g" type="number" step="0.1" />
          <FormField name="serving" label="Serving size (g)" type="number" step="0.1" />
          <Button type="submit" primary label="Submit" style={{width: '50%'}}/>
          <Button label="Delete"  style={{width: '50%'}} onClick={() => this.deleteFoodWithConfirmation(selectedFood)} />
        </Form>
        <Button label="Scan barcode" margin={{top: 'xxsmall'}} onClick={() => showBarcodeScannerAction()}/>
      </Box>
    );
  }
}

export default inject('store')(observer(AddMeal));