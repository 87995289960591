import React, { Component } from 'react';

import { Box, Button, Heading, Keyboard } from "grommet";
import { Previous, Next } from "grommet-icons";
import FoodOfTheDayTable from "../components/FoodOfTheDayTable";
import NutritionLeft from '../components/NutritionLeft';

import { Swipeable } from 'react-swipeable'
import { inject, Observer } from 'mobx-react';
import { DEFAULT_MEAL_OF_THE_DAY } from '../stores/ObservableStore';

const styles = {
  slideContainer: {
    height: "800px",
    WebkitOverflowScrolling: 'touch', // iOS momentum scrolling
  },
  slide: {
    minHeight: "800px",
  },
}

class Days extends Component {
  state = {
    selectedFoodOfTheDayIndex: null,
    highlightedRow: {},
    isLoading: false,
    index: 0,
    animation: ['slideDown']
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.store.dateId !== this.props.store.dateId) {
      this.setState({ selectedFoodOfTheDayIndex: null})
    }
  }

  setStateToPreviousDay = () => {
    this.setState({ animation: [] },
        () => this.setState({ animation: ['slideRight'] }))
    this.props.store.setStateToPreviousDay();
  }

  setStateToNextDay = () => {
    this.setState({ animation: [] },
        () => this.setState({ animation: ['slideLeft'] }))
    this.props.store.setStateToNextDay();
  }

  selectFood = (food, showSidebar) => {
    if (this.props.store.selectedFoodOfTheDay.created && food.created === this.props.store.selectedFoodOfTheDay.created) {
      this.props.store.setSelectedFoodOfTheDay(DEFAULT_MEAL_OF_THE_DAY);
      showSidebar(false);
      this.setState({ highlightedRow: {} });
    } else {
      const rowHighlight = {};
      rowHighlight[food.created] = { background: "gray" };
      this.props.store.setSelectedFoodOfTheDay(food);
      showSidebar(true);
      this.setState({ highlightedRow: rowHighlight });
    }
  }

  setStateToNextFood = (showSidebar) => {
    const ind = this.nextInd(this.props.store.foods, this.state.selectedFoodOfTheDayIndex);
    const food = this.props.store.foods[ind];
    if (!food) return;

    this.setState({ selectedFoodOfTheDayIndex: ind });
    this.selectFood(food, showSidebar);

    return food;
  }

  setStateToPreviousFood = (showSidebar) => {
    const ind = this.prevInd(this.props.store.foods, this.state.selectedFoodOfTheDayIndex);
    const food = this.props.store.foods[ind];
    if (!food) return;

    this.setState({ selectedFoodOfTheDayIndex: ind });
    this.selectFood(food, showSidebar);

    return food;
  }

  nextInd = (arr, ind) => {
    if (ind === null) return 0;
    return (ind + 1) % arr.length;
  }

  prevInd = (arr, ind) => {
    if (ind === null) return 0;
    return (ind - 1 + arr.length) % arr.length;
  }

  scrollIntoView(element) {
    element.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    })
  }

  render() {
    const {
      showSidebarAction,
    } = this.props.store;
    return (
      <Keyboard target='document'
        onLeft={arg => this.props.store.showSidebar ? null : this.setStateToPreviousDay()}
        onRight={arg => this.props.store.showSidebar ? null : this.setStateToNextDay()}
        onUp={arg => {
          // Disable these for now. It's probably a bad UX anyways.

          // arg.preventDefault();
          // if (this.props.store.showSidebar) return;
          // const food = this.setStateToPreviousFood(() => {});
          // if (!food) return;

          // const element = document.getElementById(food.id);
          // this.scrollIntoView(element);
        }}
        onDown={arg => {
          // Disable these for now. It's probably a bad UX anyways.

          // arg.preventDefault();
          // if (this.props.store.showSidebar) return;
          // const food = this.setStateToNextFood(() => {});
          // if (!food) return;

          // const element = document.getElementById(food.id);
          // this.scrollIntoView(element);
        }}
        onEnter={arg => {
          // Disable these for now. It's probably a bad UX anyways.
          
          // showSidebarAction(!this.props.store.showSidebar)
        }}
      >
        <Swipeable
          onSwipedRight={arg => this.setStateToPreviousDay()}
          onSwipedLeft={arg => this.setStateToNextDay()}
          style={{"overflowY": "auto", "height": "100vh"}} // make this scrollable, and sidebar fixed when it is open
        >
          <Observer>
            {() =>
              <Box align="center" pad="none">
                <Box direction="row" elevation="medium" margin={{bottom: "large"}} fill={true} justify="between" background="light-3" style={{position: "sticky", top: 0, zIndex: 1}}>
                  <Button hoverIndicator="light-1" onClick={() => this.setStateToPreviousDay()}>
                    <Box pad="medium" direction="row" align="center" gap="small">
                      <Previous />
                    </Box>
                  </Button>
                  <Box justify="center">{this.props.store.dateId}</Box>
                  <Button hoverIndicator="light-1" onClick={() => this.setStateToNextDay()}>
                    <Box pad="medium" direction="row" align="center" gap="small">
                      <Next />
                    </Box>
                  </Button>
                </Box>
                <Box align="center" pad="none" animation={this.state.animation}>
                  <NutritionLeft total={this.props.store.foodsLeft} target={this.props.store.foodsTarget} />
                  {/* <NutritionLeft total={this.state.foodsTotal} target={this.state.foodsTarget}/> */} {/*TODO make this a setting*/}
                  <FoodOfTheDayTable isLoading={this.state.isLoading} selectFood={this.selectFood} highlightedRow={this.state.highlightedRow} />
                </Box>
              </Box>
            }
          </Observer>
        </Swipeable>
      </Keyboard>
    );
  }
}

export default inject('store')(Days);