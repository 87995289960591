import React, { Component } from 'react';

import { Box, Form, FormField, Button } from "grommet";

import { DbService } from '../services/DbService';

class Settings extends Component {
    state = {
        target: {
            protein: 0,
            fat: 0,
            carbs: 0,
            carbUpProtein: 0,
            carbUpFat: 0,
            carbUpCarbs: 0,
        }
    }

    submitForm = (event) => {
        const settings = event.value;

        settings.protein = parseFloat(settings.protein);
        settings.fat = parseFloat(settings.fat);
        settings.carbs = parseFloat(settings.carbs);

        settings.carbUpProtein = parseFloat(settings.carbUpProtein);
        settings.carbUpFat = parseFloat(settings.carbUpFat);
        settings.carbUpCarbs = parseFloat(settings.carbUpCarbs);

        DbService.submitSettings(settings).then(res => {
            alert(res);
        })
    }

    componentDidMount = () => {
        DbService.getSettingsAndPopulateWithDefaultIfEmpty().then(settings => {
            this.setState({ target: settings });
        })
    }

    render() {
        return (
            <Box align="center" pad="large" animation={['slideDown']}>
                <Form onSubmit={this.submitForm} value={this.state.target} onChange={(target) => this.setState({target: target})}>
                    <FormField name="protein" label="Daily protein target" type="number" step="0.1" />
                    <FormField name="fat" label="Daily fat target" type="number" step="0.1" />
                    <FormField name="carbs" label="Daily carb target" type="number" step="0.1" />
                    <FormField name="carbUpProtein" label="Carb-up protein target" type="number" step="0.1" />
                    <FormField name="carbUpFat" label="Carb-up fat target" type="number" step="0.1" />
                    <FormField name="carbUpCarbs" label="Carb-up carb target" type="number" step="0.1" />
                    <Button type="submit" primary label="Submit" />
                </Form>
            </Box>
        )
    }
}

export default Settings;