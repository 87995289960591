import React from "react";

import {
  Text,
  Box,
  DataTable,
  ResponsiveContext,
  Stack,
  Heading,
  Button,
} from "grommet";

import Spinner from "./Spinner";
import { inject, Observer } from 'mobx-react';
import { PreviousDayPicker } from "./PreviousDayPicker";
import {Add, Duplicate, Subtract, Trash} from "grommet-icons";
import {findRefFoodByFoodRef, recalcNutrition} from "../util";
import FoodOfTheDayTableItemMobile from "./FoodOfTheDayTableItemMobile";

const FoodOfTheDayTable = ({highlightedRow, isLoading, selectFood, store: {
  changePageAction,
  copyFoodToToday,
  deleteFoodOfTheDay,
  editFoodOfDay,
  foods,
  foodsAll,
  showConfirmationAction,
  showSidebarAction
}}) => {
  return (
    <ResponsiveContext.Consumer>
      {size =>
        <Observer>
          {() => {
            return (
              <Stack anchor="center">
                <Box align="center" pad="large" style={{ color: isLoading ? '#DADADA' : '' }}>
                  {foods.length === 0 ?
                    <Box align="center">
                      <Heading level='3'>Copy a previous day</Heading>
                      <PreviousDayPicker/>
                    </Box>
                    :
                    <DataTable
                      columns={size === 'small' ? basicColumns : getExtendedColumns(editFoodOfDay, foodsAll, showConfirmationAction, deleteFoodOfTheDay, copyFoodToToday)}
                      data={foods}
                      // step={10}
                      onClickRow={event => selectFood(event.datum, size === 'small' || size === 'medium' ? () => changePageAction("addmealoftheday") : showSidebarAction)} // TODO refactor this
                      primaryKey="created"
                      rowProps={highlightedRow}
                      sortable={true}
                      sort={{
                        direction: "asc",
                        property: "created"
                      }}
                    />}
                </Box>
                {isLoading ? <Spinner size={40} /> : ''}
              </Stack>
            )
          }}
        </Observer>
      }
    </ResponsiveContext.Consumer>
  );
}

export default inject('store')(FoodOfTheDayTable);

const updateMeasurement = (editFoodOfDay, food, newMeasurement, foodsAll)  => {
  editFoodOfDay(food,
      {
        ...food,
        measurement: newMeasurement,
        ...recalcNutrition(food.unit, newMeasurement, findRefFoodByFoodRef(food.foodRef, foodsAll))
      })
}

let getExtendedColumns = (editFoodOfDay, foodsAll, showConfirmationAction, deleteFoodOfTheDay, copyFoodToToday) => {
  return [
    {
      property: 'name',
      header: <Text>Name</Text>,
      render: item => <Box id={item.id} direction="row" align="center" alignSelf="start">
        <Button style={{padding: 9}} icon={<Duplicate size='small'/>} onClick={(e) => {
            copyFoodToToday(item);
          e.stopPropagation();
        }}/>
        {item.name}
      </Box>,
      footer: 'Total',
    },
    {
      property: 'measurement',
      header: 'Measurement',
      render: item => <Box direction="row" align="center" alignSelf="start">
        <Box>
          <Button style={{padding: 9}} icon={<Add size='small'/>} onClick={(e) => {
              updateMeasurement(editFoodOfDay, item, item.measurement + 1, foodsAll)
            e.stopPropagation();
          }}/>
          <Button style={{padding: 9}} icon={<Subtract size='small'/>} onClick={(e) => {
            updateMeasurement(editFoodOfDay, item, item.measurement - 1, foodsAll)
            e.stopPropagation();
          }}/>
        </Box>
        {item.measurement ? item.measurement + " " + item.unit : ""}
      </Box>,
      align: 'end',
    },
    {
      property: 'protein',
      header: 'Protein',
      render: item => item.protein.toFixed(1) + "g",
      align: 'end',
      aggregate: 'sum',
      footer: { aggregate: true },
    },
    {
      property: 'fat',
      header: 'Fat',
      render: item => item.fat.toFixed(1) + "g",
      align: 'end',
      aggregate: 'sum',
      footer: { aggregate: true },
    },
    {
      property: 'carbs',
      header: 'Carbs',
      render: item => item.carbs.toFixed(1) + "g",
      align: 'end',
      aggregate: 'sum',
      footer: { aggregate: true },
    },
    {
      property: 'calories',
      header: 'Calories',
      render: item => item.calories.toFixed(1),
      align: 'end',
      aggregate: 'sum',
      footer: { aggregate: true },
    },
    {
      property: 'created',
      header: 'Added',
      render: item => <Box direction="row" align="center">
        {item.created && typeof item.created.toDate === 'function' ? item.created.toDate().toLocaleTimeString() : ''}
        <Button style={{padding: 9}} icon={<Trash size='small'/>} onClick={(e) => {
          showConfirmationAction(() => deleteFoodOfTheDay(item));
          e.stopPropagation();
        }}/>
      </Box>,
      align: 'end',
    },
  ];
}
export const basicColumns = [
  {
    property: 'name',
    header: <Text>Foods</Text>,
    render: item => <FoodOfTheDayTableItemMobile item={item} id={item.id}/>,
  },
]