import React, { Component } from 'react';
import {
  Box,
  Form,
  FormField,
  Select,
  RadioButtonGroup,
  Button
} from 'grommet';

import { ButtonLoader } from './ButtonLoader';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { recalcNutrition } from "../util";

export const DEFAULT_MEAL = { foodRef: "", unit: "gramm", measurement: 0, name: "", protein: 0, fat: 0, carbs: 0 };

class AddMealOfTheDay extends Component {
  state = {
    isSubmitting: false,
    isDeleting: false,
  }

  goBack = () => {
    if (this.props.goBackAction) {
      this.props.goBackAction();
    } else {
      this.props.store.showSidebarAction(false);
    }
  }

  howMuchCanIEatToString(food) {
    if (food.serving === 0) {
      return food.canEat.toFixed(1) + " g"
    } else {
      return (food.canEat / food.serving).toFixed(1) + " servings"
    }
  }

  submitForm = (event) => {
    const food = event.value;
    const toInsert = {
      foodRef: food.foodRef,
      unit: food.unit,
      measurement: parseFloat(food.measurement),
      name: food.name,
      protein: parseFloat(food.protein),
      fat: parseFloat(food.fat),
      carbs: parseFloat(food.carbs),
      created: new Date(),
      // created: this.props.store.selectedFoodOfTheDay.created ? this.props.store.selectedFoodOfTheDay.created : new Date()
    }
    this.setState({isSubmitting: true});
    this.props.store.upsertFoodOfTheDay(toInsert, () => {
      this.setState({isSubmitting: false});
      // this.goBack();
    });
  }

  deleteFoodWithConfirmation(food) {
    if (!food) return;
    this.props.store.showConfirmationAction(() => this.deleteFood(food));
  }

  deleteFood = (food) => {
    this.setState({ isDeleting: true });
    this.props.store.deleteFoodOfTheDay(toJS(food), () => {
      this.setState({isDeleting: false});
      this.goBack();
    });
  }

  showMeasurementSelector = (refFood) => {
    return refFood && refFood.serving !== 0;
  }

  changeFoodRef = ({ option }) => {
    const unit = option.serving === 0 ? "gramm" : "serving";
    this.props.store.setFoodOfTheDayFormValue({ ...DEFAULT_MEAL, foodRef: option.name, name: option.name, unit: unit });
    const measurement = unit === "serving" ? 1 : 100;
    this.changeMeasurement(measurement);
  }

  changeMeasurement = (value) => {
    const newNutrition = recalcNutrition(this.props.store.foodOfTheDayFormValue.unit, value, this.props.store.refFood);
    this.props.store.setFoodOfTheDayFormValue({ ...this.props.store.foodOfTheDayFormValue, ...newNutrition, measurement: value });
  }

  render() {
    const {
      selectedFoodOfTheDay,
      foodOfTheDayFormValue,
      foodsFiltered,
      refFood,
      setFoodOfTheDayFormValue,
      setFoodsFilter,
      copyFoodToToday
    } = this.props.store;
    return (
      <Box>
        <Form onSubmit={event => this.submitForm(event)} value={foodOfTheDayFormValue} onChange={food => setFoodOfTheDayFormValue(food)}>
          <Select
            size="medium"
            placeholder="Select"
            value={refFood}
            options={foodsFiltered}
            labelKey={option => refFood && refFood.name === option.name ? option.name : option.name + " | " + this.howMuchCanIEatToString(option)}
            onChange={arg => this.changeFoodRef(arg)}
            onClose={() => setFoodsFilter('')}
            onSearch={text => setFoodsFilter(text)}
          />
          {refFood ? <Box>You can have {this.howMuchCanIEatToString(refFood)} of this today.</Box> : ''}
          {this.showMeasurementSelector(refFood) ?
            <RadioButtonGroup
            name="unit"
            options={["serving", "gramm"]}
            onChange={(event) => {
              const value = event.target.value;
              const newNutrition = recalcNutrition(value, foodOfTheDayFormValue.measurement, refFood);
              setFoodOfTheDayFormValue({ ...foodOfTheDayFormValue, ...newNutrition, unit: value });
            }}
            /> :
            ""
          }
          <FormField name="measurement" label={this.showMeasurementSelector(refFood) && foodOfTheDayFormValue.unit === "serving" ? "Number of servings" : "Weight (g)"} type="number" step="0.1" onChange={event => this.changeMeasurement(event.target.value)} />
          <FormField name="name" label="Name" />
          <FormField name="protein" label="Protein (g)" type="number" step="0.1" />
          <FormField name="fat" label="Fat (g)" type="number" step="0.1" />
          <FormField name="carbs" label="Carbs (g)" type="number" step="0.1" />
          <ButtonLoader type="submit" primary label="Submit" isLoading={this.state.isSubmitting} />
          <ButtonLoader label="Delete" isLoading={this.state.isDeleting} onClick={() => this.deleteFoodWithConfirmation(selectedFoodOfTheDay)} />
          <Button label='Copy' onClick={() => copyFoodToToday(selectedFoodOfTheDay)}></Button>
        </Form>
      </Box>
    );
  }
}

export default inject('store')(observer(AddMealOfTheDay));