import React from 'react';
import { observer } from 'mobx-react'
import { useStores } from '../stores/useStores';
import { Calendar } from 'grommet';

export const DayPicker = observer(() => {
  const store = useStores();

  return (
    <Calendar
      date={store.dateId}
      onSelect={(date) => {
        store.setDateAction(date);
        if (store.page !== "days") {
          store.changePageAction("days");
        }
      }}
      firstDayOfWeek={1}
      daysOfWeek={true}
    />
  );
});