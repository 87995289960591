import React from 'react';
import BarCodeScanner from 'barcode-react-scanner';
 
const BarcodeScanner = (props) => {
  
 return (
     <BarCodeScanner onUpdate={ (err, resp) => {
         if(resp) {
             props.onScan(resp.text);
         }
      }}
    />
 );
 
}
 
export default BarcodeScanner;