export const recalcNutrition = (unit, measurement, refFood) =>
{
    if (!refFood) {
        // We don't have any reference food, so we can't recalculate anything
        return;
    }
    const serving = refFood.serving || 0;
    const refProtein = refFood.protein || 0;
    const refFat = refFood.fat || 0;
    const refCarbs = refFood.carbs || 0;

    let ratio;
    if (unit === "serving") {
        ratio = serving / 100;
    } else if (unit === "gramm") {
        ratio = measurement / 100 / measurement;
    }

    return {
        protein: parseFloat((refProtein * ratio * measurement).toFixed(1)),
        fat: parseFloat((refFat * ratio * measurement).toFixed(1)),
        carbs: parseFloat((refCarbs * ratio * measurement).toFixed(1)),
    }
}

export const findRefFoodByFoodRef = (foodRef, foodsList) => {
    return foodsList.find(x => x.name === foodRef);
}