import React, {useState} from "react";
import {inject, observer} from 'mobx-react';
import {Box, Button, Card, CardBody, CardFooter, CardHeader} from "grommet";
import {Add, Duplicate, Subtract, Trash} from "grommet-icons";
import {findRefFoodByFoodRef, recalcNutrition} from "../util";

const FoodOfTheDayTableItemMobile = observer(({item, store}) => {
    const [animation, setAnimation] = useState([]);
    const {
        editFoodOfDay,
        foodsAll,
        showConfirmationAction,
        deleteFoodOfTheDay,
        copyFoodToToday,
    } = store;
    return (
        <Card pad="none" animation={animation}>
            <CardHeader pad={{vertical: "small"}} background="light-1"><b>{item.name}</b></CardHeader>
            <CardBody pad="none">
                <Box direction="column">
                    <Box direction="row">
                        <Box basis="1/2" align="start">Measurement:</Box>
                        <Box basis="1/2">{item.measurement}&nbsp;{item.unit}</Box>
                    </Box>
                    <Box direction="row">
                        <Box basis="1/2" align="start">Protein:</Box>
                        <Box basis="1/2">{item.protein.toFixed(1)}g</Box>
                    </Box>
                    <Box direction="row">
                        <Box basis="1/2" align="start">Fat:</Box>
                        <Box basis="1/2">{item.fat.toFixed(1)}g</Box>
                    </Box>
                    <Box direction="row">
                        <Box basis="1/2" align="start">Carbs:</Box>
                        <Box basis="1/2">{item.carbs.toFixed(1)}g</Box>
                    </Box>
                    <Box direction="row">
                        <Box basis="1/2" align="start">Calories:</Box>
                        <Box basis="1/2">{item.calories.toFixed(1)}kcal</Box>
                    </Box>
                </Box>
            </CardBody>
            <CardFooter pad={{vertical: "small"}} background="light-2">
                <Button icon={<Duplicate color="plain" onClick={(e) => {
                    copyFoodToToday(item);
                    e.stopPropagation();
                }}/>}/>
                <Button icon={<Add color="plain" onClick={(e) => {
                    updateMeasurement(editFoodOfDay, item, item.measurement + 1, foodsAll)
                    e.stopPropagation();
                }}/>}/>
                <Button icon={<Subtract color="plain" onClick={(e) => {
                    updateMeasurement(editFoodOfDay, item, item.measurement - 1, foodsAll)
                    e.stopPropagation();
                }}/>}/>
                <Button icon={<Trash color="red" onClick={(e) => {
                    showConfirmationAction(() => {
                        setAnimation(['fadeOut'], deleteFoodOfTheDay(item));
                    });
                    e.stopPropagation();
                }}/>}/>
            </CardFooter>
        </Card>
    );
})

const updateMeasurement = (editFoodOfDay, food, newMeasurement, foodsAll) => {
    editFoodOfDay(food,
        {
            ...food,
            measurement: newMeasurement,
            ...recalcNutrition(food.unit, newMeasurement, findRefFoodByFoodRef(food.foodRef, foodsAll))
        })
}

export default inject('store')(FoodOfTheDayTableItemMobile);