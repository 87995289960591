import React, { Component } from 'react';

import { Text, Box, DataTable, ResponsiveContext } from "grommet";

import { inject, Observer } from 'mobx-react';
import { DEFAULT_MEAL } from '../components/AddMealOfTheDay';

class Foods extends Component {
  selectFood = (food) => {
    if (this.props.store.selectedFood.name === food.name) {
      this.props.store.showSidebarAction(false);
      this.props.store.setSelectedFood(DEFAULT_MEAL);
    } else {
      this.props.store.showSidebarAction(true);
      this.props.store.setSelectedFood(food);
    }
  }

  getRowProps = () => {
    const props = {};
    props[this.props.store.selectedFood.name] = { background: "red" };
    return props;
  }

  render() {
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Observer>
            {() =>
              <Box align="center" pad="large" animation={['slideDown']}
                   style={{"overflowY": "auto", "height": "100vh"}} //make this scrollable, and sidebar fixed when it is open
              >
                <DataTable
                  columns={size === 'small' ? basicColumns : extendedColumns}
                  data={this.props.store.foodsAll}
                  step={15} // TODO get rid of this
                  sortable={true}
                  rowProps={this.getRowProps()}
                  primaryKey="name"
                  onClickRow={event => this.selectFood(event.datum)}
                />
              </Box>
            }
          </Observer>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

export const basicColumns = [
  {
    property: 'name',
    header: <Text>Name</Text>,
    primary: true,
    search: true,
  },
]

export const extendedColumns = [
  ...basicColumns,
  {
    property: 'protein',
    header: 'Protein',
    render: item => item.protein + "g",
    align: 'end',
  },
  {
    property: 'fat',
    header: 'Fat',
    render: item => item.fat + "g",
    align: 'end',
  },
  {
    property: 'carbs',
    header: 'Carbs',
    render: item => item.carbs + "g",
    align: 'end',
  },
  {
    property: 'calories',
    header: 'Calories',
    render: item => item.calories.toFixed(1),
    align: 'end',
  },
  {
    property: 'serving',
    header: 'Serving',
    render: item => item.serving + "g",
    align: 'end',
  },
];

export default inject('store')(Foods);