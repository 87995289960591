import { decorate, observable, computed, runInAction, action, autorun } from "mobx"
import { DbService } from '../services/DbService'
import * as moment from "moment";
import {findRefFoodByFoodRef} from "../util";

export const DEFAULT_MEAL = { name: "", protein: 0, fat: 0, carbs: 0, serving: 0 };
export const DEFAULT_MEAL_OF_THE_DAY = { foodRef: "", unit: "gramm", measurement: 0, name: "", protein: 0, fat: 0, carbs: 0 };

class ObservableStore {
    date = new Date();
    dateId = this.date.toDateString();
    days = [];
    daysFilter = {start: moment().weekday(0), end: moment().weekday(0).add(7, 'days')}
    foods = [];
    foodsAll = [];
    foodsFilter = '';
    foodsLoading = false;
    foodsTarget = { protein: 0, fat: 0, carbs: 0, cal: 0, };
    settings = {}
    selectedFood = DEFAULT_MEAL;
    foodFormValue = DEFAULT_MEAL;
    selectedFoodOfTheDay = DEFAULT_MEAL_OF_THE_DAY;
    foodOfTheDayFormValue = DEFAULT_MEAL_OF_THE_DAY;

    showSidebar = false;
    showNavbar = true;
    page = window.location.pathname;
    toastMessage = undefined;
    showConfirmation = false;
    showBarcodeScanner = false;
    confirmationCallback = undefined;
    // selectedFoodIndex = null;
    // highlightedRow = {};
    // foodsTotal = { protein: 0, fat: 0, carbs: 0, cal: 0 };
    // foodsTarget = { protein: 0, fat: 0, carbs: 0, cal: 0, };
    // foodsLeft = { protein: 0, fat: 0, carbs: 0, cal: 0 };
    // isLoading = false;

    goBackToPageAction = (newPage) => {
        if (newPage !== this.page) {
            this.page = newPage;
        }
    }

    changePageAction = (newPage) => {
        window.history.pushState({}, "", "/" + newPage);
        this.page = "/" + newPage;
    }

    showToastAction = (message) => {
        this.toastMessage = message;
        window.setTimeout(this.hideToastAction, 3000);
    }

    hideToastAction = () => {
        this.toastMessage = undefined;
    }

    showConfirmationAction = (callback) => {
        this.showConfirmation = true;
        this.confirmationCallback = callback;
    }

    hideConfirmationAction = () => {
        this.showConfirmation = false;
        this.confirmationCallback = undefined;
    }

    showBarcodeScannerAction = () => {
        this.showBarcodeScanner = true;
    }

    hideBarcodeScannerAction = () => {
        this.showBarcodeScanner = false;
    }

    barcodeScannedAction = (barcode) => {
        this.queryFoodData(barcode);
    }

    queryFoodData = async (barcode) => {
        const url = `https://world.openfoodfacts.org/api/v0/product/${barcode}.json`;
        const res = await fetch(url);
        const json = await res.json();

        const status = json.status_verbose;

        if (status === "product found") {
            const product_name = json.product.product_name;
    
            const nutriments = json.product.nutriments;
            const protein = nutriments.proteins_100g;
            const fat = nutriments.fat_100g;
            const carbs = nutriments.carbohydrates_100g;
    
            if (product_name && protein && fat && carbs) {
                const newFoodFormValue = {...this.foodFormValue, name: product_name, protein: protein, fat: fat, carbs: carbs}
                
                console.log(newFoodFormValue);
                runInAction(() => {
                    this.foodFormValue = newFoodFormValue;
                })

                this.showToastAction(`${product_name} found in database, form updated with new data.`)
            } else {
                this.showToastAction("Missing data in database for given product.")
            }
        } else {
            this.showToastAction("Product not found in database.")
        }
        this.hideBarcodeScannerAction();
    }

    showSidebarAction = (show) => {
        this.showSidebar = show;
    }

    showNavbarAction = (show) => {
        this.showNavbar = show;
    }

    setSelectedFood = (food) => {
        this.selectedFood = food;
        this.foodFormValue = food;
    }

    setSelectedFoodOfTheDay = (food) => {
        this.selectedFoodOfTheDay = food;
        this.foodOfTheDayFormValue = food;
    }

    setFoodFormValue = (food) => {
        this.foodFormValue = food;
    }

    setFoodOfTheDayFormValue = (food) => {
        this.foodOfTheDayFormValue = food;
    }

    setDateAction = (isoDate) => {
        this.date = new Date(isoDate);
        this.dateId = this.date.toDateString();
    }

    setStateToPreviousDay = () => {
        const prevDay = this.prevDay(this.date);
        runInAction(() => {
            this.date = prevDay;
            this.dateId = this.date.toDateString();
        })
    }

    setStateToNextDay = () => {
        const nextDay = this.nextDay(this.date);
        runInAction(() => {
            this.date = nextDay;
            this.dateId = this.date.toDateString();
        })
    }

    prevDay = (date) => {
        date.setDate(date.getDate() - 1);
        return date;
    }

    nextDay = (date) => {
        date.setDate(date.getDate() + 1);
        return date;
    }

    copyFoodsOfDay1ToDay2 = (day1, day2) => {
        DbService.getFoodListOfDay(day1).then(data => {
            let counter = 0;
            let len = data.length;

            data.forEach(item => {
                const itemCopy = {...item};
                let currentDayDate = new Date(day2);

                let created = item.created.toDate();
                created.setFullYear(currentDayDate.getFullYear());
                created.setMonth(currentDayDate.getMonth());
                created.setDate(currentDayDate.getDate());
                
                itemCopy.created = created;
                delete itemCopy.calories

                DbService.addFoodToDay(day2, itemCopy).then(() => {
                    counter++;
                    if (counter === len) {
                        this.showToastAction(day1 + " was copied");
                        this.getFoodsOfTheDay(this.dateId);
                    }
                })
            })
        })
    }

    editFoodOfDay = (oldFood, newFood) => {
        DbService.editFoodOfDay(this.dateId, oldFood, newFood)
            .then(() => {
                this.showToastAction(newFood.name + " was edited");
                this.getFoodsOfTheDay(this.dateId);
            });
    }

    copyFoodToToday = (food) => {
        this.copyFoodToDay(new Date(), food, () => {
            this.getFoodsOfTheDay(this.dateId);
        });
    }

    copyFoodToDay = (day, food, callback = () => null) => {
        const foodCopy = {...food};
    
        let created = new Date();
        // Make sure food will have correct date for specified day
        created.setFullYear(day.getFullYear());
        created.setMonth(day.getMonth());
        created.setDate(day.getDate());
        
        foodCopy.created = created;
        delete foodCopy.calories

        DbService.addFoodToDay(day.toDateString(), foodCopy).then(() => {
            this.showToastAction(food.name + " was copied to " + day.toDateString());
            callback();
        })
    }

    setDaysFilterToPrevWeek = () => {
        this.daysFilter = {start: this.daysFilter.start.clone().subtract(7, 'days'), end: this.daysFilter.end.subtract(7, 'days')};
    }

    setDaysFilterToNextWeek = () => {
        this.daysFilter = {start: this.daysFilter.start.clone().add(7, 'days'), end: this.daysFilter.end.add(7, 'days')};
    }

    getFoodsAll = () => {
        DbService.getFoods().then(data => {
            this.foodsAll = data;
            this.DEFAULT_FOODS = data;
        })
    }

    getFoodsOfTheDay = (dateId) => {
        this.foodsLoading = true;
        DbService.getFoodListOfDay(dateId).then(data => {
            this.foods = data;
            this.foodsLoading = false;
        })
    }

    getDays = () => {
        DbService.getDays().then(data => {
            this.days = data;
        })
    }

    autoRunHowMuchICanEat = autorun(() => {
        const data = this.foodsAll;
        this.addHowMuchCanIEat(data, this.foodsLeft);
        this.foodsAll = data;
        this.DEFAULT_FOODS = data;
    })

    fetchFoodsAll = autorun(() => {
        this.getFoodsAll();
    })

    fetchFoods = autorun(() => {
        this.getFoodsOfTheDay(this.dateId);
    });

    upsertFoodOfTheDay = (food, callBack) => {
        if (this.foodOfTheDayFormValue.created) {
            DbService.editFoodOfDay(this.dateId, this.selectedFoodOfTheDay, food)
                .then(() => {
                    this.showToastAction(food.name + " was edited");
                    this.getFoodsOfTheDay(this.dateId);
                    callBack();
                });
        } else {
            DbService.addFoodToDay(this.dateId, food)
                .then(() => {
                    this.showToastAction(food.name + " was added");
                    this.getFoodsOfTheDay(this.dateId);
                    callBack();
                });
        }
    }

    deleteFoodOfTheDay = (food, callBack = () => null) => {
        DbService.deleteMeal(this.dateId, food).then(() => {
            this.showToastAction(food.name + " was deleted");
            this.setFoodsFilter('');
            this.getFoodsOfTheDay(this.dateId);
            callBack();
        });
    }

    addHowMuchCanIEat(foods, left) {
        foods.forEach(item => {
            item.canEat = this.howMuchCanIEat(item, left)
        });
    }

    howMuchCanIEat = (food, left) => {
        return Math.min(left.protein / food.protein, left.fat / food.fat, left.carbs / food.carbs) * 100;
    }

    fetchTarget = autorun(() => {
        // TODO further optimise this. no need for fetching everytime
        const isItSaturday = this.isItSaturday(this.dateId);
        const isItSunday = this.isItSunday(this.dateId);
        DbService.getSettingsAndPopulateWithDefaultIfEmpty().then(settings => {
            this.settings = settings;
            const target = (isItSaturday || isItSunday) ?
                { protein: settings.carbUpProtein, fat: settings.carbUpFat, carbs: settings.carbUpCarbs } :
                { protein: settings.protein, fat: settings.fat, carbs: settings.carbs };

            target.cal = (target.protein * 4 + target.carbs * 4 + target.fat * 9);
            this.foodsTarget = target;
        })
    });

    isItSaturday(dateId) {
        return dateId.split(' ')[0] === 'Sat';
    };

    isItSunday(dateId) {
        return dateId.split(' ')[0] === 'Sun';
    };

    get foodsLeft() {
        const target = this.foodsTarget;
        const total = this.foodsTotal;

        const diff = {
            protein: parseFloat((target.protein - total.protein).toFixed(1)),
            fat: parseFloat((target.fat - total.fat).toFixed(1)),
            carbs: parseFloat((target.carbs - total.carbs).toFixed(1)),
            cal: parseFloat((target.cal - total.cal).toFixed(1))
        }

        return diff;
    }

    get foodsTotal() {
        const sum = {
            protein: 0,
            fat: 0,
            carbs: 0,
            cal: 0
        }

        this.foods.reduce((acc, val) => {
            acc.protein += val.protein;
            acc.fat += val.fat;
            acc.carbs += val.carbs;
            acc.cal += (val.protein * 4 + val.carbs * 4 + val.fat * 9)

            return acc;
        }, sum)

        sum.protein = parseFloat(sum.protein.toFixed(1));
        sum.fat = parseFloat(sum.fat.toFixed(1));
        sum.carbs = parseFloat(sum.carbs.toFixed(1));
        sum.cal = parseFloat(sum.cal.toFixed(1));

        return sum;
    }

    get foodsFiltered() {
        // The line below escapes regular expression special characters:
        // [ \ ^ $ . | ? * + ( )
        const escapedText = this.foodsFilter.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");

        // Create the regular expression with modified value which
        // handles escaping special characters. Without escaping special
        // characters, errors will appear in the console
        const exp = new RegExp(escapedText, "i");
        return this.foodsAll.filter(f => exp.test(f.name));
    }

    get daysFiltered() {
        return this.days.filter(x => {
            const date = moment(x.unixtime);
            return date.isAfter(this.daysFilter.start) && date.isBefore(this.daysFilter.end);
        });
    }

    get refFood() {
        return findRefFoodByFoodRef(this.foodOfTheDayFormValue.foodRef, this.foodsAll);
    }

    setFoodsFilter = (filter) => {
        this.foodsFilter = filter;
    }
}

decorate(ObservableStore, {
    dateId: observable,
    foods: observable,
    foodsAll: observable,
    foodsFilter: observable,
    foodsTarget: observable,
    settings: observable,
    days: observable,
    daysFilter: observable,
    selectedFood: observable,
    selectedFoodOfTheDay: observable,
    foodFormValue: observable,
    foodOfTheDayFormValue: observable,
    
    getFoodsOfTheDay: action,
    getFoodsAll: action,
    getDays: action,
    setSelectedFood: action,
    setSelectedFoodOfTheDay: action,
    setFoodFormValue: action,
    setFoodOfTheDayFormValue: action,
    setFoodsFilter: action,
    setDaysFilterToPrevWeek: action,
    setDaysFilterToNextWeek: action,
    upsertFoodOfTheDay: action,
    deleteFoodOfTheDay: action,
    copyFoodsOfDay1ToDay2: action,
    copyFoodToDay: action,
    copyFoodToToday: action,
    editFoodOfDay: action,

    foodsLeft: computed,
    foodsTotal: computed,
    foodsFiltered: computed,
    daysFiltered: computed,
    refFood: computed,

    showSidebar: observable,
    showNavbar: observable,
    page: observable,
    toastMessage: observable,
    showConfirmation: observable,
    confirmationCallback: observable,
    showBarcodeScanner: observable,

    changePageAction: action,
    showToastAction: action,
    hideToastAction: action,
    showConfirmationAction: action,
    hideConfirmationAction: action,
    showBarcodeScannerAction: action,
    hideBarcodeScannerAction: action,
    barcodeScannedAction: action,
    showSidebarAction: action,
    showNavbarAction: action,
    setDateAction: action,
    goBackToPageActoin: action,
})

export const store = new ObservableStore();