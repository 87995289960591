import React from "react";

import { Box, Meter, Stack, Text } from "grommet";

const LabelledMeter = (props) => {
  const meterValue = props.value;
  const unit = props.unit;
  const text = props.text;

  return (
    <Box align="center" pad="none">
      <Stack anchor="center">
        <Meter
          type="circle"
          background="light-2"
          values={[{ value: meterValue, color: getBarColor(meterValue, props.max) }]}
          size="xsmall"
          thickness="small"
          max={props.max}
        />
        <Box align="center">
          <Box direction="row" align="center" pad={{ bottom: "xsmall" }}>
            <Text size="medium" weight="bold">
              {meterValue}
            </Text>
            <Text size="small">{unit}</Text>
          </Box>
          <Text size="xsmall">{text}</Text>
        </Box>
      </Stack>
    </Box>
  );
};

const getBarColor = (value, max) => {
  const currentRatio = value / max;
  if (currentRatio >= 0.8) {
    return '';
  }
  if (currentRatio >= 0.6) {
    return 'accent-3';
  }
  if (currentRatio >= 0.4) {
    return 'accent-4';
  }
  if (currentRatio >= 0.2) {
    return 'status-warning';
  }
  
  return 'status-critical';
}

export default LabelledMeter;