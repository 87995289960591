import React, { Component } from 'react';
import {
  Box,
  Button,
  Collapsible,
  Heading,
  Grommet,
  Layer,
  ResponsiveContext,
  Text,
  Menu,
  DropButton,
} from 'grommet';
import { Close, Add, Calendar, MoreVertical, Book } from 'grommet-icons';
import { grommet } from "grommet/themes";
import Body from './Body';
import firebase from "firebase/app";
import {
  FirebaseAuthProvider,
  IfFirebaseAuthedAnd,
  IfFirebaseUnAuthed,
} from "@react-firebase/auth";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { config } from './firebase';
import AddMealOfTheDay from './components/AddMealOfTheDay';
import { inject, Observer } from 'mobx-react';
import AddMeal from './components/AddMeal';
import { DayPicker } from './components/DayPicker';
import BarcodeScanner from './components/BarcodeScanner';

const theme = {
  global: {
    colors: {
      brand: '#228BE6',
    },
    // font: {
    //   family: 'Roboto',
    //   size: '14px',
    //   height: '20px',
    // },
    font: grommet.global.font,
    breakpoints: {
      small: { value: 666 }
    }
  },
};


// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.GithubAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

const AppBar = (props) => (
  <Box
    tag='header'
    direction='row'
    align='center'
    justify='between'
    background='brand'
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation='medium'
    style={{ zIndex: '1' }}
    {...props}
  />
);

const ToastLayer = ({ message, onClose }) => (
  <Layer modal={false} full='horizontal' position='bottom' responsive={false}>
    <Box
      background='light-3'
      pad='small'
      direction='row'
      justify='between'
      align='center'
    >
      <Text size='large'>{message}</Text>
      <Button icon={<Close />} onClick={onClose} />
    </Box>
  </Layer>
);

const ConfirmationLayer = ({ onYes, onNo }) => (
  <Layer position='top' onClickOutside={onNo} responsive={false}>
    <Box pad='large' gap='medium'>
      <Text>Are you sure?</Text>
      <Box direction='row' gap='medium' align='center'>
        <Button label='Yes' onClick={onYes} />
        <Button label='No' primary={true} onClick={onNo} />
      </Box>
    </Box>
  </Layer>
);

const BarcodeScannerLayer = ({ onScan, onExit }) => (
  <Layer position='top' onClickOutside={onExit} reponsive={false}>
    <Box pad='large' gap='medium'>
      <BarcodeScanner onScan={onScan}/>
      <Box direction='row' gap='medium' align='center'>
        <Button label='Exit' primary={true} onClick={onExit} />
      </Box>
    </Box>
  </Layer>
);

class App extends Component {
  componentDidMount = () => {
    window.onpopstate = (arg) => {
      this.props.store.goBackToPageAction(window.location.pathname)
    }
  }

  render() {
    return (
      <FirebaseAuthProvider firebase={firebase} {...config}>
        <IfFirebaseAuthedAnd
          filter={({ providerId }) => providerId !== "anonymous"}
        >
        {({ providerId }) => {
          return <Grommet theme={theme} full>
            <ResponsiveContext.Consumer>
              {size => (
                <Observer>
                  {() => {
                    const {
                      showSidebar,
                      page,
                      toastMessage,
                      showConfirmation,
                      showBarcodeScanner,
                      hideBarcodeScannerAction,
                      barcodeScannedAction,
                      confirmationCallback,
                      changePageAction,
                      hideToastAction,
                      hideConfirmationAction,
                      showSidebarAction,
                      setDateAction,
                    } = this.props.store;
                    return (
                      <Box fill>
                        <Box fill>
                          <AppBar direction='column' align='start'>
                            <Box fill='horizontal' direction="row" align="center" gap="small" justify="between" pad={{ left: 'medium', right: 'small', vertical: 'small' }}>
                              <Box direction="row" align="center" onClick={() => {
                                setDateAction(new Date().toISOString())
                                changePageAction('days');
                              }}>
                                <Book />
                                <Heading level='3' margin='xsmall'>Food Journal</Heading>
                              </Box>
                              {/* <Heading level='3' margin='none' onClick={() => showNavbarAction(!showNavbar)}>Food Journal</Heading> */}
                              <Box direction="row">
                                <DropButton
                                  icon={<Calendar />}
                                  dropAlign={{ top: 'bottom', right: 'right' }}
                                  dropContent={
                                    <DayPicker />
                                  }
                                />
                                <Button
                                  icon={<Add />}
                                  onClick={() => {
                                    let newPage;

                                    if (size === 'small' || size === 'medium') {
                                      switch (page.substring(1)) {
                                        case '':
                                        case 'days':
                                          newPage = 'addmealoftheday';
                                          break;
                                        case 'addmealoftheday':
                                          newPage = 'days';
                                          break;
                                        case 'foods':
                                          newPage = 'addmeal';
                                          break;
                                        case 'addmeal':
                                          newPage = 'foods';
                                          break;
                                        default:
                                          newPage = '';
                                      }

                                      changePageAction(newPage);
                                    } else {
                                      showSidebarAction(!showSidebar);
                                    }
                                  }}
                                />
                                <Menu
                                  icon={<MoreVertical />}
                                  items={[
                                    { label: 'Foods', onClick: () => changePageAction("foods") },
                                    { label: 'Settings', onClick: () => changePageAction("settings") },
                                    { label: 'Analytics', onClick: () => changePageAction("analytics") },
                                  ]}
                                />
                              </Box>
                            </Box>
                          </AppBar>
                          <Box direction='row' flex overflow={{ horizontal: 'hidden' }}>
                            {/* <Collapsible direction="horizontal" open={showNavbar} style={{ position: 'fixed', height: '100%' }}>
                          <Box
                            flex
                            width='small'
                            background='light-2'
                            elevation='small'
                            align='center'
                            justify='start'
                          >
                            <Nav direction="column" align="center" >
                              <Anchor onClick={() => changePageAction("days")} label={"Days"} />
                              <Anchor onClick={() => changePageAction("foods")} label={"Foods"} />
                              <Anchor onClick={() => changePageAction("settings")} label={"Settings"} />
                            </Nav>
                          </Box>
                        </Collapsible> */}
                            <Box flex align='center' justify='start'>
                              <Body page={page} />
                            </Box>
                            {(!showSidebar || size === 'large') ? (
                              <Collapsible direction="horizontal" open={showSidebar}>
                                <Box
                                  flex
                                  width='medium'
                                  background='light-2'
                                  elevation='small'
                                  align='center'
                                  justify='start'
                                  pad={{ top: 'medium' }}
                                >
                                  {page === '/days' || page === '/' ?
                                    <AddMealOfTheDay />
                                    : page === '/foods' ?
                                      <AddMeal />
                                      : ''}
                                </Box>
                              </Collapsible>
                            ) : ""}
                          </Box>
                        </Box>
                        {toastMessage ? <ToastLayer message={toastMessage} onClose={() => hideToastAction()} /> : ""}
                        {showConfirmation ? <ConfirmationLayer onNo={() => hideConfirmationAction()} onYes={() => { confirmationCallback(); hideConfirmationAction() }} /> : ""}
                        {showBarcodeScanner ? <BarcodeScannerLayer onScan={barcodeScannedAction} onExit={hideBarcodeScannerAction}/> : ""}
                      </Box>
                    )
                  }}
                </Observer>
              )}
            </ResponsiveContext.Consumer>
          </Grommet>
        }}
        </IfFirebaseAuthedAnd>
        <IfFirebaseUnAuthed>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        </IfFirebaseUnAuthed>
      </FirebaseAuthProvider>
    );
  }
}

export default inject('store')(App);