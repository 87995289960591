import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../stores/useStores';
import { Box, Button, Heading } from 'grommet';
import {
    AreaChart, BarChart, Bar, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import { Previous, Next } from "grommet-icons";
import * as moment from "moment"; // TODO don't import everything

export default observer(() => {
    const store = useStores();

    useEffect(() => {
        store.getDays();
    }, [store])

    const targetCalories = calculateTargetCalories(store.daysFiltered, store.settings);
    const consumedCalories = calculateConsumedCalories(store.daysFiltered);
    const calorieDifference = (targetCalories - consumedCalories).toFixed(2);
    return (
        <Box align="center" pad="large">
            <Box direction="row">
                <Button hoverIndicator="light-1" onClick={() => store.setDaysFilterToPrevWeek()}>
                    <Box pad="large" direction="row" align="center" gap="small">
                        <Previous />
                    </Box>
                </Button>
                <Heading tag="h3">Week starting {store.daysFilter.start.clone().add(1, 'days').format("dddd, MMMM Do")}</Heading>
                <Button hoverIndicator="light-1" onClick={() => store.setDaysFilterToNextWeek()}>
                    <Box pad="large" direction="row" align="center" gap="small">
                        <Next />
                    </Box>
                </Button>
            </Box>
            {true ?
            <ResponsiveContainer width="100%" height="80%">
                <BarChart
                    data={store.daysFiltered}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey='date' />
                    <YAxis />
                    <Tooltip
                        formatter={(value, name, props) => [
                            name === 'calories.fat' ? + (value / 9).toFixed(0) + 'g/' + value.toFixed(0) + ' cal' : (value / 4).toFixed(0) + 'g/' + value.toFixed(0) + ' cal',
                            name.split('.')[1]]
                        }
                        labelFormatter={(unixTime) => new Date(unixTime).toDateString()}
                    />
                    <Bar dataKey="calories.protein" fill="#8884d8" stackId="unixtime" />
                    <Bar dataKey="calories.fat" fill="#82ca9d" stackId="unixtime" />
                    <Bar dataKey="calories.carbs" fill="#ffc658" stackId="unixtime" />
                </BarChart>
                </ResponsiveContainer> :
                <AreaChart
                    width={1400}
                    height={800}
                    data={store.daysFiltered}
                    margin={{
                        top: 10, right: 30, left: 0, bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey='unixtime'
                        domain={['auto', 'auto']}
                        name='Time'
                        tickFormatter={(unixTime) => moment(unixTime).format('DD/MM')}
                        type='number'
                        tickCount={7}
                    />
                    <YAxis />
                    <Tooltip
                        formatter={(value, name, props) => [
                            name === 'calories.fat' ? + (value / 9).toFixed(0) + 'g/' + value.toFixed(0) + ' cal' : (value / 4).toFixed(0) + 'g/' + value.toFixed(0) + ' cal',
                            name.split('.')[1]]
                        }
                        labelFormatter={(unixTime) => new Date(unixTime).toDateString()}
                    />
                    <Area type="monotone" dataKey="calories.protein" stackId="1" stroke="#8884d8" fill="#8884d8" />
                    <Area type="monotone" dataKey="calories.fat" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                    <Area type="monotone" dataKey="calories.carbs" stackId="1" stroke="#ffc658" fill="#ffc658" />
                </AreaChart>
            }
            <Box direction="row" gap="small">
                <Box>Target calories: {targetCalories} cal</Box>
                <Box>Consumed calories: {consumedCalories} cal</Box>
                <Box>Difference: {calorieDifference} cal</Box>
            </Box>
        </Box >
    );
})

// TODO move these to util function
const calculateConsumedCalories = (foods) => {
    const total = foods.reduce((acc, val) => acc + (val.calories ? val.calories.total : 0), 0);
    return total.toFixed(2);
}

const calculateTargetCalories = (foods, settings) => {
    const total = foods.reduce((acc, val) => {
        const toAdd = (isItSaturday(val.date) || isItSunday(val.date)) ? settings.carbUpProtein * 4 + settings.carbUpFat * 9 + settings.carbUpCarbs * 4 : settings.protein * 4 + settings.fat * 9 + settings.carbs * 4;
        return acc + toAdd;
    }, 0);
    return total.toFixed(2);
}

const isItSaturday = (dateId) => {
    return dateId.split(' ')[0] === 'Sat';
};

const isItSunday = (dateId) => {
    return dateId.split(' ')[0] === 'Sun';
};