import { db } from '../firebase';
import firebase from "firebase/app";
import "firebase/auth";
import * as d3 from "d3"; // TODO don't import everything

const auth = firebase.auth();

const cache = {};

const makeSureUserIsLoggedIn = () => {
  return new Promise((res, rej) => {
    if (auth.currentUser == null) {
      auth.onAuthStateChanged(function (user) {
        if (user) {
          res(user);
        } else {
          // TODO disable this for now, we are using react components to force auth in app.js but might need this later
          // const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
          // auth.signInWithRedirect(googleAuthProvider);
        }
      });
    } else {
      res(auth.currentUser);
    }
  })
}
// TODO delete this
const getUserUid = () => {
  if (auth.currentUser !== null) {
    return auth.currentUser.uid;
  }

  return null;
}

// TODO rename to get day
const getFoodListOfDay = (dayId) => {
  return new Promise(async (resolution, rejection) => {
    await makeSureUserIsLoggedIn();

    const data = cachedData(dayId);
    if (data) {
      resolution(data);
      return;
    }

    db.collection("users/" + getUserUid() + "/day").doc(dayId).get().then((doc) => {
      const day = doc.data();

      let foods = (day !== undefined && day.foods) || [];
      foods = foods.map(food => ({ calories: calculateCalories(food.protein, food.fat, food.carbs), ...food }));

      addToCache(dayId, foods)
      resolution(foods);
    })
      .catch((error) => {
        console.error(error);
        rejection(error);
      });
  })
};

const getLatestDay = () => {
  return new Promise(async (resolutionFunc, rejectionFunc) => {
    await makeSureUserIsLoggedIn();

    db.collection("users/" + getUserUid() + "/day").orderBy("date", "desc").limit(1).get().then((querySnapshot) => {
      const currentDay = querySnapshot.docs[0];
      if (!currentDay) {
        rejectionFunc("No data");
        return;
      }
      resolutionFunc(currentDay);
    });
  });
};

const getFoods = () => {
  return new Promise(async (resolution, rejection) => {
    await makeSureUserIsLoggedIn();
    const userId = getUserUid();

    const data = cachedData(userId);
    if (data) {
      resolution(data);
      return;
    }

    db.collection("users").doc(getUserUid()).get().then((doc) => {
      const day = doc.data();
      let foods = (day !== undefined && day.foods) || [];

      foods = foods.map(food => ({ calories: calculateCalories(food.protein, food.fat, food.carbs), ...food }));

      addToCache(userId, foods);
      resolution(foods);
    })
      .catch((error) => {
        console.error(error);
        rejection(error);
      });
  })
}

const getDays = () => {
  return new Promise(async (res, rej) => {
    await makeSureUserIsLoggedIn();
    const userId = getUserUid();
    const cacheKey = userId + 'days';

    const data = cachedData(cacheKey);
    if (data) {
      res(data);
      return;
    }

    let days = [];
    var parseTime = d3.timeParse("%a %b %d %Y");
    db.collection("users/" + userId + "/day").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const data = doc.data();

        let foods = data.foods || [];

        foods = foods.map(food => ({ calories: calculateCalories(food.protein, food.fat, food.carbs), ...food }));

        const cals = foods.reduce((sum, curr) => sum + curr.calories, 0);
        const protein = foods.reduce((sum, curr) => sum + curr.protein, 0);
        const fat = foods.reduce((sum, curr) => sum + curr.fat, 0);
        const carbs = foods.reduce((sum, curr) => sum + curr.carbs, 0);

        const calories = {
          total: cals,
          protein: protein * 4,
          fat: fat * 9,
          carbs: carbs * 4,
        }

        const parsed = parseTime(doc.id);
        const unixtime = parsed.getTime(); // Tue Jun 30 2015 00:00:00 GMT-0700 (PDT)

        const day = { date: doc.id, ...data, foods: foods, calories: calories, protein: protein, fat: fat, carbs: carbs, unixtime: unixtime };
        days.push(day);
      });

      days.sort((a, b) => a.unixtime - b.unixtime);

      addToCache(cacheKey, days);
      res(days);
    }).catch((err) => {
      console.log(err);
      rej(err);
    });
  })
}

const deleteMeal = (dayId, meal) => {
  meal = convertMealToStoredFormat(meal);

  return new Promise(async (resolution, rejection) => {
    await makeSureUserIsLoggedIn();

    db.collection("users/" + getUserUid() + "/day").doc(dayId).update({
      foods: firebase.firestore.FieldValue.arrayRemove(meal)
    }).then((data) => {
      invalidateCache(dayId);
      resolution(data);
    })
      .catch((error) => {
        console.error(error);
        rejection(error);
      });
  })
}

const deleteFood = (food) => {
  food = convertFoodToStoredFormat(food);

  return new Promise(async (resolution, rejection) => {
    await makeSureUserIsLoggedIn();

    db.collection("users").doc(getUserUid()).update({
      foods: firebase.firestore.FieldValue.arrayRemove(food)
    }).then((data) => {
      invalidateCache(getUserUid());
      resolution(data);
    })
      .catch((error) => {
        console.error(error);
        rejection(error);
      });
  })
}

const createDayIfDoesntExist = (dayId) => {
  return new Promise(async (res, rej) => {
    await makeSureUserIsLoggedIn();

    db.collection("users/" + getUserUid() + "/day").doc(dayId).get()
      .then((doc) => {
        if (!doc.exists) {
          db.collection("users/" + getUserUid() + "/day").doc(dayId).set({
            created: new Date()
          }).then(() => res()).catch(err => {
            console.log(err);
            rej(err);
          });
        } else {
          res();
        }
      })
      .catch((err) => rej(err));
  })
}

const addFoodToDay = (dayId, data) => {
  data = convertMealToStoredFormat(data);
  return new Promise(async (resolution, rejection) => {
    await makeSureUserIsLoggedIn();

    await createDayIfDoesntExist(dayId);

    db.collection("users/" + getUserUid() + "/day").doc(dayId).update({
      foods: firebase.firestore.FieldValue.arrayUnion(data)
    }).then((data) => {
      invalidateCache(dayId);
      resolution(data);
    })
      .catch((error) => {
        console.error(error);
        rejection(error);
      });
  })
};

const addFood = (name, data) => {
  return new Promise(async (resolution, rejection) => {
    await makeSureUserIsLoggedIn();

    const toInsert = { name: name, ...data };

    db.collection("users").doc(getUserUid()).update(
      'foods', firebase.firestore.FieldValue.arrayUnion(toInsert)
    ).then((data) => {
      invalidateCache(getUserUid());
      resolution(data);
    })
      .catch((error) => {
        console.error(error);
        rejection(error);
      });
  })
};

const editFoodOfDay = (dayId, oldFood, newFood) => {
  return new Promise(async (resolution, rejection) => {
    await makeSureUserIsLoggedIn();

    await deleteMeal(dayId, oldFood);

    addFoodToDay(dayId, newFood)
      .then((data) => {
        invalidateCache(dayId);
        resolution(data)
      }).catch((error) => {
        console.error(error);
        rejection(error);
      })
  })
};

const submitSettings = (settings) => {
  return new Promise(async (resolution, rejection) => {
    await makeSureUserIsLoggedIn();

    db.collection("users").doc(getUserUid()).update(
      {'settings': settings}
    )
      .then((data) => {
        resolution(data);
      })
      .catch((error) => {
        console.error(error);
        rejection(error);
      });
  })
};

const getSettingsAndPopulateWithDefaultIfEmpty = () => {
  return new Promise(async (resolution, rejection) => {
    await makeSureUserIsLoggedIn();

    const userRef = db.collection("users").doc(getUserUid());

    userRef.get()
      .then(async (doc) => {
        const data = doc.data();

        const defaultSettings = {
          protein: 0,
          fat: 0,
          carbs: 0,
          carbUpProtein: 0,
          carbUpFat: 0,
          carbUpCarbs: 0,
        }

        // New users won't have their document 'created' yet, so here we will
        // make sure they'll have one
        let settings;
        if (data === undefined) {
          await userRef.set({settings: defaultSettings});
          settings = defaultSettings;
        } else if (!('settings' in data)) {
          await userRef.update({settings: defaultSettings})
          settings = defaultSettings;
        } else {
          settings = data['settings'];
        }
        
        resolution(settings);
      })
      .catch((error) => {
        console.error(error);
        rejection(error);
      });
  })
};

const convertFoodToStoredFormat = (food) => {
  return {
    name: food.name,
    protein: food.protein,
    fat: food.fat,
    carbs: food.carbs,
    serving: food.serving
  }
}

const convertMealToStoredFormat = (meal) => {
  return {
    name: meal.name,
    protein: meal.protein,
    fat: meal.fat,
    carbs: meal.carbs,
    measurement: meal.measurement,
    unit: meal.unit,
    foodRef: meal.foodRef,
    created: meal.created,
  }
}

export const DbService = {
  getFoodListOfDay: getFoodListOfDay,
  getLatestDay: getLatestDay,
  getFoods: getFoods,
  getDays: getDays,
  addFoodToDay: addFoodToDay,
  addFood: addFood,
  editFoodOfDay: editFoodOfDay,
  deleteMeal: deleteMeal,
  deleteFood: deleteFood,
  submitSettings: submitSettings,
  getSettingsAndPopulateWithDefaultIfEmpty: getSettingsAndPopulateWithDefaultIfEmpty,
}

const calculateCalories = (protein, fat, carbs) => {
  return protein * 4 + carbs * 4 + fat * 9;
}

const addToCache = (id, obj) => {
  cache[id] = obj;
}

const invalidateCache = (id) => {
  cache[id] = null;
}

const cachedData = (id) => {
  if (cache[id]) {
    return cache[id];
  }
  return null;
}