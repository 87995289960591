import React from 'react';
import { observer } from 'mobx-react'
import { useStores } from '../stores/useStores';
import { Calendar } from 'grommet';

export const PreviousDayPicker = observer(() => {
  const store = useStores();

  return (
    <Calendar
      date={store.dateId}
      onSelect={(date) => {
        const selectedDate = (new Date(date)).toDateString();
        const currentDate = store.dateId;
        store.copyFoodsOfDay1ToDay2(selectedDate, currentDate);
      }}
      firstDayOfWeek={1}
      daysOfWeek={true}
    />
  );
});