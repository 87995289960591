import React from 'react';
import { Box, Button } from 'grommet';
import { LinkPrevious } from 'grommet-icons';

const PopupPage = (props) => {
    return (
        <Box align="center">
            <Box fill='horizontal'>
                <Button icon={<LinkPrevious />} onClick={() => props.goBackAction()}></Button>
            </Box>
            <Box>
                {props.children}
            </Box>
        </Box>
    );
}

export default PopupPage;