import React from 'react';
import {
  Box,
} from 'grommet';
import Foods from './pages/Foods';
import Days from './pages/Days';
import Settings from './pages/Settings';
import Analytics from './pages/Analytics';
import AddMeal from './components/AddMeal';
import AddMealOfTheDay from './components/AddMealOfTheDay';
import PopupPage from './components/PopupPage';
import { observer } from 'mobx-react';
import { useStores } from './stores/useStores';

const Body = (props) => {
  const store = useStores();

  const {
      changePageAction,
      showSidebar,
  } = store;

  return (
    <Box fill='horizontal' style={showSidebar ? {"overflow": "hidden", "margin": 0, "height": "100%"} : {}}>
      {
        props.page === "/foods" ?
          <Foods /> :
        props.page === "/settings" ?
          <Settings /> :
        props.page === "/addmealoftheday" ?
          <PopupPage goBackAction={() => changePageAction('days')}>
            <AddMealOfTheDay goBackAction={() => changePageAction('days')} />
          </PopupPage> :
        props.page === "/addmeal" ?
          <PopupPage goBackAction={() => changePageAction('foods')}>
            <AddMeal goBackAction={() => changePageAction('foods')} />
          </PopupPage> :
        props.page === "/analytics" ?
          <Analytics /> :
          <Days />
      }
    </Box>
  )
}

export default observer(Body);