import React, { Component } from "react";
import { Button, Box } from "grommet";
import Spinner from './Spinner';

export class ButtonLoader extends Component {
  render() {
    const { isLoading, onClick } = this.props;
    return (
      <Button
        {...this.props}
        onClick={!isLoading ? onClick : null}
        label={
          isLoading ? (
            <Box direction="row" gap="small">
              {this.props.label}
              <Spinner />
            </Box>
          ) : (
              <Box direction="row" gap="small">
                {this.props.label}
              </Box>
            )
        }
      />
    );
  }
}
